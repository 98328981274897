import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
// import * as dat from "dat.gui";
import { gsap } from "gsap";
import { LogLuvEncoding, Material } from "three";
import Archivo_Bold from "../static/fonts/Archivo_Bold.json";
import Roboto_Bold from "../static/fonts/Roboto_Bold.json";



// import SimplexNoise from "https://cdnjs.cloudflare.com/ajax/libs/simplex-noise/2.4.0/simplex-noise.min.js"


/**
 * html elements to call
 */
const loadingBarLogo = document.querySelector(".metsi-logo");
const loadingBarElement = document.querySelector(".spin");
const loadingBarText = document.querySelector(".text");
const maindiv = document.querySelector(".container");
const intro = document.querySelector(".number1");
const step1 = document.querySelector(".number2");
const step2 = document.querySelector(".number3");
const step2a = document.querySelector(".number3a");
const step3 = document.querySelector(".number4");
const rehost = document.querySelector(".column-path1")
const rehost2 = document.querySelector(".column-path1-2")
const replatform = document.querySelector(".column-path2")
const replatform2 = document.querySelector(".column-path2-2")
const repurchase = document.querySelector(".column-path3")
const repurchase2 = document.querySelector(".column-path3-2")
const refactor = document.querySelector(".column-path4")
const refactor2 = document.querySelector(".column-path4-2")
const encapsulate = document.querySelector(".column-path5")
const encapsulate2 = document.querySelector(".column-path5-2")
const retain = document.querySelector(".column-path6")
const retain2 = document.querySelector(".column-path6-2")
const retire = document.querySelector(".column-path7")
const retire2 = document.querySelector(".column-path7-2")
const summaryinfo = document.querySelector(".column-path8-2")
const spaces = document.querySelectorAll(".spaces")
const foot1 = document.querySelector(".step1")
const foot2 = document.querySelector(".step2")
const foot3 = document.querySelector(".step3")
const foot1b = document.querySelector(".step1b")
const foot2b = document.querySelector(".step2b")
const foot3b = document.querySelector(".step3b")
const modal = document.querySelector(".paths-modal")
const loadingscreen = document.querySelector(".wrapper1")
const begin = document.querySelector(".begin")
const transparentcover = document.querySelector(".transparent-cover")

const welcomemodal = document.querySelector(".welcome-modal")
const soundon = document.querySelector(".soundon")
const soundoff = document.querySelector(".soundoff")

// console.log(spaces[1])

/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager(
  //Loaded
  () => {
    // console.log("loaded");
    gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 });

    loadingBarElement.classList.add("ended");

    foot1.classList.add("click")
    foot1b.classList.add("click")

    begin.classList.add("loaded")

    loadingBarLogo.classList.add("ended");
    loadingBarText.classList.add("ended");
    loadingscreen.classList.add("ended");
  
    transparentcover.classList.add("click")
 
  },

  //Progress
  () => {
    // console.log("progress");
  }
);
const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
const loader = new THREE.CubeTextureLoader(loadingManager);
const textureLoader = new THREE.TextureLoader(loadingManager);
const fontLoader = new THREE.FontLoader(loadingManager);

// document.getElementById("loading-screen-btn").addEventListener("click",function(){
//   loadingBarLogo.classList.add("ended");
//   loadingBarText.classList.add("ended");
//   loadingscreen.classList.add("ended");

//   transparentcover.classList.add("click")

 
// })

document.getElementById("close-welcome").addEventListener("click", function(){

  welcomemodal.classList.add("click")

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 5100)

  window.setTimeout(() => {
    maindiv.classList.add("load")
    welcomemodal.classList.add("hide")
    sweep.play()
  }, 2500)

  window.setTimeout(() => {
    gsap.to(camera.position, { duration: 6, x: -0.9 });
    gsap.to(camera.position, { duration: 6, y: -0.6 });
    gsap.to(camera.position, { duration: 6, z: 1 });
  }, 100)

})

document.getElementById("close-welcome2").addEventListener("click", function(){

  welcomemodal.classList.add("click")

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 5100)

  window.setTimeout(() => {
    maindiv.classList.add("load")
    welcomemodal.classList.add("hide")
    sweep.play()
  }, 2500)

  window.setTimeout(() => {
    gsap.to(camera.position, { duration: 6, x: -0.9 });
    gsap.to(camera.position, { duration: 6, y: -0.6 });
    gsap.to(camera.position, { duration: 6, z: 1 });
  }, 100)

})

/**
 * Base
 */
// Debug
// const gui = new dat.GUI();


// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Overlay
 */
// const loadingTexture = textureLoader.load('sky.jpg')
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms: {
    uAlpha: { value: 1 },
  },
  vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
  fragmentShader: `
        uniform float uAlpha;

    void main()
    {
        gl_FragColor = vec4(0, 0.091, 0.133, uAlpha);
    }
    `,
});

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Models
 */
dracoLoader.setDecoderPath("/draco/");

gltfLoader.setDRACOLoader(dracoLoader);

let mixer = null;


gltfLoader.load("/models/everest.glb", (gltf) => {
  scene.add(gltf.scene);
  gltf.scene.position.set(0.25, 0.5, -0.25);
  gltf.scene.scale.set(1, 2.5, 1);

  // camera.lookAt(gltf)

  // gsap.to(gltf.scene.rotation, {duration: 10, y: 1.25*Math.PI/2})
});

// fog

scene.fog = new THREE.Fog(0xcccccc, 0.1, 4);

let logo;
{
  gltfLoader.load("/models/metsi-logo.glb", (gltf) => {
    scene.add(gltf.scene);
    gltf.scene.position.set(0.45, 0.3, 0);
    gltf.scene.scale.set(1.5, 1.5, 1.5);
    gltf.scene.rotation.set(Math.PI / 2, 0, 0);
    logo = gltf;
    // console.log(gltf)
  });
}

//Cube

//Geometry


//Material


//Points


/**
 * Floor
 */
const floor = new THREE.Mesh(
  new THREE.PlaneGeometry(50, 50),
  new THREE.MeshStandardMaterial({
    color: "#444444",
    metalness: 0,
    roughness: 0.5,
  })
);
floor.receiveShadow = true;
floor.rotation.x = -Math.PI * 0.5;
// scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(5, 5, 5);
scene.add(directionalLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.01,
  10
);

const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);

scene.add(camera);
camera.setFocalLength(7.5);
camera.position.y = 0.25;
camera.position.x = 1.6;
camera.position.z = 0.25;

// gui.add(camera.position, "x").min(-1.5).max(3);
// gui.add(camera.position, "z").min(-1).max(1);
// gui.add(camera.position, "y").min(-1).max(1);

// gui.add(camera.rotation, 'z').min(0).max(10);
// gui.add(camera.rotation, "y").min(0).max(10);
// gui.add(camera.rotation, "x").min(0).max(10);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.set(0, -0.5, 0);
controls.enableDamping = true;

//Skybox
const skytexture = loader.load([
  "/skybox/Right.bmp",
  "/skybox/Left.bmp",
  "/skybox/Top.bmp",
  "/skybox/Bottom.bmp",
  "/skybox/Front.bmp",
  "/skybox/Back.bmp",
]);
scene.background = skytexture;

// gsap.to(scene.background, {duration: 10, rotation: 1.25*Math.PI/2})
// console.log(scene.background);



/**
 * Audio
 */

// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add( listener );

// create a global audio source
const sound = new THREE.Audio( listener );
const sound2 = new THREE.Audio( listener );
const sound3 = new THREE.Audio( listener );
const sound4 = new THREE.Audio( listener );
const sweep = new THREE.Audio( listener );

// load a sound and set it as the Audio object's buffer
const audioLoader1 = new THREE.AudioLoader();
const audioLoader2 = new THREE.AudioLoader();
const audioLoader3 = new THREE.AudioLoader();
const audioLoader4 = new THREE.AudioLoader();
const audioLoader5 = new THREE.AudioLoader();

document.getElementById("sound-state").innerHTML = "Off"

audioLoader1.load( 'sounds/music.mp3', function( buffer ) {
	sound.setBuffer( buffer );
	sound.setLoop( true );
	sound.setVolume( 1 );
});

audioLoader5.load( 'sounds/sweep.mp3', function( buffer ) {
	sweep.setBuffer( buffer );
	sweep.setLoop( false );
	sweep.setVolume( 0 );
});

  document.getElementById("startButton").addEventListener("click", function(){
    sound.play();
    sound2.setVolume(0.25)
    sound3.setVolume(0.2)
    sound4.setVolume(0.2)
    sweep.setVolume(1)
    soundon.classList.add("click")
    soundoff.classList.add("click")
    document.getElementById("sound-state").innerHTML = "On"
  })
  document.getElementById("stopButton").addEventListener("click", function(){
    sound2.setVolume(0)
    sound3.setVolume(0)
    sound4.setVolume(0)
    sweep.setVolume(0)
    sound.pause();
    soundon.classList.remove("click")
    soundoff.classList.remove("click")
    document.getElementById("sound-state").innerHTML = "Off"
  })

/**
 * Click Sound
 */

audioLoader2.load( 'sounds/click.mp3', function( buffer ) {
	sound2.setBuffer( buffer );
	sound2.setLoop( false );
	sound2.setVolume( 0 );

  document.getElementById("startButton").addEventListener("click", function(){
    sound2.play();
    soundon.classList.add("click")
    soundoff.classList.add("click")
  })
  document.getElementById("stopButton").addEventListener("click", function(){
    sound2.play();
    soundon.classList.remove("click")
    soundoff.classList.remove("click")
  })
  // document.getElementById("loading-screen-btn").addEventListener("click", function(){
  //   sound2.play();
  // })
  document.getElementById("builtauxo").addEventListener("click", function(){
    sound2.play();
    sound.stop();
  })
  document.getElementById("builtauxo2").addEventListener("click", function(){
    sound2.play();
    sound.stop();
  })
  document.getElementById("close-welcome").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("btn1").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("contact-btn1").addEventListener("click", function(){
    window.open("https://metsi.co/contact");
    sound2.play();
    sound.pause();
  })
  document.getElementById("contact-btn2").addEventListener("click", function(){
    window.open("https://metsi.co/contact");
    sound2.play();
    sound.pause();
  })
  document.getElementById("contact-btn3").addEventListener("click", function(){
    window.open("https://metsi.co/contact");
    sound2.play();
    sound.pause();
  })
  document.getElementById("logo-header").addEventListener("click", function(){
    sound2.play();
    sound.pause();
  })
  document.getElementById("btn2").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("rehost").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("replatform").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("repurchase").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("refactor").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("encapsulate").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("retain").addEventListener("click", function(){
    sound2.play();
  })
  document.getElementById("retire").addEventListener("click", function(){
    sound2.play();
  })
});

/**
 * Hover Sound
 */

 audioLoader3.load( 'sounds/hover.mp3', function( buffer ) {
	sound3.setBuffer( buffer );
	sound3.setLoop( false );
	sound3.setVolume( 0 );

  // document.getElementById("startButton").addEventListener("mouseover", function(){
  //   sound3.play();
  // })
  // document.getElementById("stopButton").addEventListener("mouseover", function(){
  //   sound3.play();
  // })
  // document.getElementById("loading-screen-btn").addEventListener("mouseover", function(){
  //   sound3.play();
  // })
  document.getElementById("close-welcome").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("btn1").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("contact-btn1").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("contact-btn2").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("contact-btn3").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("logo-header").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("btn2").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("rehost").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("replatform").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("repurchase").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("refactor").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("encapsulate").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("retain").addEventListener("mouseover", function(){
    sound3.play();
  })
  document.getElementById("retire").addEventListener("mouseover", function(){
    sound3.play();
  })
});



/**
 * Hover off Sound
 */

 audioLoader4.load( 'sounds/hover2.mp3', function( buffer ) {
	sound4.setBuffer( buffer );
	sound4.setLoop( false );
	sound4.setVolume( 0 );

  // document.getElementById("startButton").addEventListener("mouseout", function(){
  //   sound4.play();
  // })
  // document.getElementById("stopButton").addEventListener("mouseout", function(){
  //   sound4.play();
  // })
  // document.getElementById("loading-screen-btn").addEventListener("mouseout", function(){
  //   sound4.play();
  // })
  document.getElementById("close-welcome").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("btn1").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("contact-btn1").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("contact-btn2").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("contact-btn3").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("logo-header").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("btn2").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("rehost").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("replatform").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("repurchase").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("refactor").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("encapsulate").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("retain").addEventListener("mouseout", function(){
    sound4.play();
  })
  document.getElementById("retire").addEventListener("mouseout", function(){
    sound4.play();
  })
});



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

//Mouse

window.addEventListener("wheel", onScroll);

let y = 0;

function onScroll(event) {
  y = event.deltaY;
  // console.log(event);
}

document.addEventListener("mousemove", animateTerrain);

let mouseY = 0;
let mouseX = 0;

const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

function animateTerrain(event) {
  mouseY = event.clientY / windowHalfY - 1;
  mouseX = event.clientX / windowHalfX - 1;
}

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;
const elapsedTime2 = clock.getElapsedTime();







/**
 * THE STUFF THAT HAPPENS WHEN A USER CLICKS
 */

document.getElementById("foot-btn1").addEventListener("click", function() {
  // console.log("here we go again!");
  maindiv.classList.add("click")

  sound2.play()

  transparentcover.classList.add("click")

  //footer button colours
  foot1.classList.add("click")
  foot2.classList.remove("click")
  foot3.classList.remove("click")
  foot1b.classList.add("click")
  foot2b.classList.remove("click")
  foot3b.classList.remove("click")
  

  //move camera
  gsap.to(camera.position, { duration: 6, x: -0.9 });
  gsap.to(camera.position, { duration: 6, y: -0.6 });
  gsap.to(camera.position, { duration: 6, z: 1 });

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 5000)


  //show div
  window.setTimeout(() => {
    step1.classList.remove("click")
    maindiv.classList.remove("click")
    step3.classList.remove("click")
    intro.classList.remove("click")
    step2a.classList.remove("click")
    sweep.play()
  }, 2000)


});

document.getElementById("foot-btn2").addEventListener("click", function() {

  sound2.play()

  // console.log("it works!");
  maindiv.classList.add("click")

    //footer button colours
    foot1.classList.remove("click")
    foot2.classList.add("click")
    foot3.classList.remove("click")
    foot1b.classList.remove("click")
    foot2b.classList.add("click")
    foot3b.classList.remove("click")

    transparentcover.classList.add("click")

    window.setTimeout(() => {
      transparentcover.classList.remove("click")
    }, 4500)

  gsap.to(camera.position, { duration: 4.5, z: -0.8 });
  gsap.to(camera.position, { duration: 4.5, x: -0.45 });
  gsap.to(camera.position, { duration: 4.5, y: -0.15 });
  // gsap.to(camera { duration: 2, lookAt: -0.5 });
  window.setTimeout(() => {
    intro.classList.add("click")
    step1.classList.add("click")
    maindiv.classList.remove("click")
    step2a.classList.remove("click")
    step3.classList.remove("click")
    sweep.play()

  }, 3500)
});


document.getElementById("foot-btn3").addEventListener("click", function() {

  sound2.play()

  // console.log("it works, agaaaaain!");
  maindiv.classList.add("click")
  gsap.to(camera.position, { duration: 5.5, z: 0.25 });
  gsap.to(camera.position, { duration: 5.5, x: 1.6 });
  gsap.to(camera.position, { duration: 5.5, y: 0.25 });


  // window.setTimeout(() => {
  //   gsap.to(camera.position, { duration: 4.5,  x: 1.5 });
  //   gsap.to(camera.position, { duration: 4.5, y: 0.25 });
  //   gsap.to(camera.position, { duration: 4.5, z: 0 });

  // }, 3000)

  transparentcover.classList.add("click")

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 5500)
  
  window.setTimeout(() => {

    step2.classList.remove("click")
    step1.classList.remove("click")
    step2a.classList.remove("click")
    step3.classList.add("click")
    maindiv.classList.remove("click")
    intro.classList.add("click")

    sweep.play()
  }, 4000)

  //footer button colours
  foot1.classList.remove("click")
  foot2.classList.remove("click")
  foot3.classList.add("click")
  foot1b.classList.remove("click")
  foot2b.classList.remove("click")
  foot3b.classList.add("click")

});



document.getElementById("btn1").addEventListener("click", function() {
  // console.log("it works!");
  maindiv.classList.add("click")

    //footer button colours
    foot1.classList.remove("click")
    foot2.classList.add("click")
    foot3.classList.remove("click")
    foot1b.classList.remove("click")
    foot2b.classList.add("click")
    foot3b.classList.remove("click")

    gsap.to(camera.position, { duration: 4.5, z: -0.8 });
    gsap.to(camera.position, { duration: 4.5, x: -0.45 });
    gsap.to(camera.position, { duration: 4.5, y: -0.15 });

    transparentcover.classList.add("click")

    window.setTimeout(() => {
      transparentcover.classList.remove("click")
    }, 4500)
  // gsap.to(camera { duration: 2, lookAt: -0.5 });
  window.setTimeout(() => {
    intro.classList.add("click")
    step1.classList.add("click")
    maindiv.classList.remove("click")
    step2a.classList.remove("click")
    step3.classList.remove("click")

    sweep.play()
  }, 2500)
});





document.getElementById("btn2").addEventListener("click", function() {
  // console.log("it works, agaaaaain!");
  maindiv.classList.add("click")
  gsap.to(camera.position, { duration: 5.5, z: 0.25 });
  gsap.to(camera.position, { duration: 5.5, x: 1.6 });
  gsap.to(camera.position, { duration: 5.5, y: 0.25 });

  transparentcover.classList.add("click")

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 6000)
  // window.setTimeout(() => {
  //   gsap.to(camera.position, { duration: 4.5,  x: 1.5 });
  //   gsap.to(camera.position, { duration: 4.5, y: 0.25 });
  //   gsap.to(camera.position, { duration: 4.5, z: 0 });

  // }, 3000)


  
  window.setTimeout(() => {

    step2.classList.remove("click")
    step1.classList.remove("click")
    step2a.classList.remove("click")
    step3.classList.add("click")
    maindiv.classList.remove("click")
    intro.classList.add("click")

    sweep.play()
  }, 4000)

  //footer button colours
  foot1.classList.remove("click")
  foot2.classList.remove("click")
  foot3.classList.add("click")
  foot1b.classList.remove("click")
  foot2b.classList.remove("click")
  foot3b.classList.add("click")


});

document.getElementById("btn4").addEventListener("mouseover", function() {
  sound3.play()
})

document.getElementById("btn4").addEventListener("mouseout", function() {
  sound4.play()
})

document.getElementById("btn4").addEventListener("click", function() {
  sound2.play()
  // console.log("here we go again!");
  maindiv.classList.add("click")

  //footer button colours
  foot1.classList.add("click")
  foot2.classList.remove("click")
  foot3.classList.remove("click")
  foot1b.classList.add("click")
  foot2b.classList.remove("click")
  foot3b.classList.remove("click")
  
  transparentcover.classList.add("click")

  window.setTimeout(() => {
    transparentcover.classList.remove("click")
  }, 5000)

  //move camera
  gsap.to(camera.position, { duration: 6, x: -0.9 });
  gsap.to(camera.position, { duration: 6, y: -0.5 });
  gsap.to(camera.position, { duration: 6, z: 1 });

  //show div
  window.setTimeout(() => {
    step1.classList.remove("click")
    maindiv.classList.remove("click")
    step3.classList.remove("click")
    intro.classList.remove("click")
    step2a.classList.remove("click")
    sweep.play()
  }, 3000)


});




// document.getElementById("btn2").addEventListener("click", function() {
//   console.log("it works again!");
//   maindiv.classList.add("click")

//   gsap.to(camera.position, { duration: 4, z: -0.5 });
//   gsap.to(camera.position, { duration: 4, x: -0.9 });
//   gsap.to(camera.position, { duration: 4, y: 0.1 });

//   window.setTimeout(() => {
//     step1.classList.remove("click")
//     step2a.classList.add("click")
//     maindiv.classList.remove("click") 
//   }, 2500)

// });


// document.getElementById("btn5").addEventListener("click", function() {
//   console.log("it works again!");
//   maindiv.classList.add("click")
//   gsap.to(camera.position, { duration: 5,  z: 0.5 });
//   gsap.to(camera.position, { duration: 5, y: -0.5 });
//   // gsap.to(camera { duration: 2, lookAt: -0.5 });
//   window.setTimeout(() => {
//     step2a.classList.remove("click")
//     step2.classList.add("click")
//     maindiv.classList.remove("click") 
//   }, 2500)

// });




document.getElementById("btn3").addEventListener("click", function() {
  // console.log("here we go again!");
  maindiv.classList.add("click")


  gsap.to(camera.position, { duration: 6, x: -0.25 });
  gsap.to(camera.position, { duration: 6, y: -0.55 });
  gsap.to(camera.position, { duration: 6, z: -1.8 });

  window.setTimeout(() => {
    maindiv.classList.remove("click")
    step3.classList.remove("click")
    intro.classList.remove("click")
  }, 5000)


});




/**
 * Image modal popup
 */

document.getElementById("modal-view").addEventListener("click", function(){
  sound2.play();
  modal.classList.remove("click")
})

document.getElementById("paths-image").addEventListener("click", function(){
  sound2.play();
  modal.classList.add("click")
})

document.getElementById("paths-image").addEventListener("mouseover", function(){
  sound3.play();
})

// document.getElementById("paths-image").addEventListener("mouseout", function(){
//   sound4.play();
// })

document.getElementById("paths-image2").addEventListener("click", function(){


  modal.classList.add("click")
})





/**
 * Rehost
 */
document.getElementById("rehost").addEventListener("click", function(){
  rehost.classList.add("click")
  rehost2.classList.add("click")

  summaryinfo.classList.add("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")

  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  replatform2.classList.remove("click")
  repurchase2.classList.remove("click")
  refactor2.classList.remove("click")
  encapsulate2.classList.remove("click")
  retain2.classList.remove("click")
  retire2.classList.remove("click")

})

document.getElementById("rehost2").addEventListener("click", function(){
  rehost.classList.remove("click")
  rehost2.classList.remove("click")
  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Replatform
 */
 document.getElementById("replatform").addEventListener("click", function(){
  replatform.classList.add("click")
  replatform2.classList.add("click")
  
  summaryinfo.classList.add("click")

  rehost.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  rehost2.classList.remove("click")
  repurchase2.classList.remove("click")
  refactor2.classList.remove("click")
  encapsulate2.classList.remove("click")
  retain2.classList.remove("click")
  retire2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("replatform2").addEventListener("click", function(){
  replatform.classList.remove("click")
  replatform2.classList.remove("click")
  rehost.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Repurchase
 */
 document.getElementById("repurchase").addEventListener("click", function(){
  repurchase.classList.add("click")
  repurchase2.classList.add("click")

  summaryinfo.classList.add("click")
 
  replatform.classList.remove("click")
  rehost.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  replatform2.classList.remove("click")
  rehost2.classList.remove("click")
  refactor2.classList.remove("click")
  encapsulate2.classList.remove("click")
  retain2.classList.remove("click")
  retire2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("repurchase2").addEventListener("click", function(){
  repurchase.classList.remove("click")
  repurchase2.classList.remove("click")
  replatform.classList.remove("click")
  rehost.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Refactor
 */
 document.getElementById("refactor").addEventListener("click", function(){
  refactor.classList.add("click")
  refactor2.classList.add("click")

  summaryinfo.classList.add("click")

  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  rehost.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  replatform2.classList.remove("click")
  repurchase2.classList.remove("click")
  rehost2.classList.remove("click")
  encapsulate2.classList.remove("click")
  retain2.classList.remove("click")
  retire2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("refactor2").addEventListener("click", function(){
  refactor.classList.remove("click")
  refactor2.classList.remove("click")
  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  rehost.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Encapsulate
 */
 document.getElementById("encapsulate").addEventListener("click", function(){
  encapsulate.classList.add("click")
  encapsulate2.classList.add("click")

  summaryinfo.classList.add("click")

  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  rehost.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  replatform2.classList.remove("click")
  repurchase2.classList.remove("click")
  refactor2.classList.remove("click")
  rehost2.classList.remove("click")
  retain2.classList.remove("click")
  retire2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("encapsulate2").addEventListener("click", function(){
  encapsulate.classList.remove("click")
  encapsulate2.classList.remove("click")
  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  rehost.classList.remove("click")
  retain.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Retain
 */
 document.getElementById("retain").addEventListener("click", function(){
  retain.classList.add("click")
  retain2.classList.add("click")

  summaryinfo.classList.add("click")

  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  rehost.classList.remove("click")
  retire.classList.remove("click")

  replatform2.classList.remove("click")
  repurchase2.classList.remove("click")
  refactor2.classList.remove("click")
  encapsulate2.classList.remove("click")
  rehost2.classList.remove("click")
  retire2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("retain2").addEventListener("click", function(){
  retain.classList.remove("click")
  retain2.classList.remove("click")
  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  rehost.classList.remove("click")
  retire.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})

/**
 * Retire
 */
 document.getElementById("retire").addEventListener("click", function(){
  retire.classList.add("click")
  retire2.classList.add("click")

  summaryinfo.classList.add("click")

  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  rehost.classList.remove("click")

  replatform2.classList.remove("click")
  repurchase2.classList.remove("click")
  refactor2.classList.remove("click")
  encapsulate2.classList.remove("click")
  retain2.classList.remove("click")
  rehost2.classList.remove("click")

  spaces[1].classList.add("click")
  spaces[2].classList.add("click")
  spaces[3].classList.add("click")
  spaces[4].classList.add("click")
  spaces[5].classList.add("click")
  spaces[6].classList.add("click")
  spaces[0].classList.add("click")
})

document.getElementById("retire2").addEventListener("click", function(){
  retire.classList.remove("click")
  retire2.classList.remove("click")
  replatform.classList.remove("click")
  repurchase.classList.remove("click")
  refactor.classList.remove("click")
  encapsulate.classList.remove("click")
  retain.classList.remove("click")
  rehost.classList.remove("click")

  summaryinfo.classList.remove("click")

  spaces[1].classList.remove("click")
  spaces[2].classList.remove("click")
  spaces[3].classList.remove("click")
  spaces[4].classList.remove("click")
  spaces[5].classList.remove("click")
  spaces[6].classList.remove("click")
  spaces[0].classList.remove("click")
})




/**
 * ANIMATIONS
 */

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

if(logo) {
  logo.scene.rotation.z += 0.03
}


  camera.lookAt(0, 0, 0);

  if (mixer) {
    mixer.update(deltaTime);
  }

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
